import React from 'react'
import Header from '../components/header/Header'
import {FaArrowRight, FaHome} from 'react-icons/fa'
import Blog1 from "../assets/blog1.jpg"
import '../components/blog/blog.css'

const Article1 = () => {
    return (
      <div>
          <a href="/" className="link">
                    HOME
                    <FaHome className='link__icon'></FaHome>
                </a>
          <section className="blog section" id="blog">
              <br /><br /><br /><br /><br /><br />
          <h2 className="section__title text-cs">Derniers Articles</h2>
      <p className="section__subtitle">
          Mon <span>Blog</span>
      </p>
  
      <div className="blog__container container grid">
          <div className="blog__item card card-two">
              <span className="blog__date text-cs">24 FEVRIER 2023</span>
              <h3 className="blog__title">Le référencement, comment ça marche ?</h3>
              <p className="blog__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde et blanditiis officiis veritatis ratione, nostrum maiores animi totam libero temporibus...</p>
  
              <a href="/article1" className="link">
                      Lire Plus
                      <FaArrowRight className='link__icon'></FaArrowRight>
                  </a>
  
                  <img src={Blog1} alt="" className="blog__img" />
          </div>
      </div>
  
      </section>
          
      </div>
    )
  }

export default Article1