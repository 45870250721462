import React from 'react'
import profileImg from '../../assets/profile-img.png'
import shapeOne from '../../assets/shape-1.png'
import shapeTwo from '../../assets/shape-2.png'

import {FaTwitter, FaDribbble, FaBehance} from 'react-icons/fa'
import './home.css'

const home = () => {
  return (
    <section className='home' id='home'>
        <div className="home__wrapper">
        <div className="home__container container">
            <p className="home__subtitle text-cs">
                Hello, <span>Je m'appelle</span>
            </p>
            <h1 className='home__title text-cs'>
                <span>FRANCOIS-<br></br>XAVIER</span> PIN
            </h1>

            <p className="home__job">
                <span className="text-cs">Je Suis</span> <b>Webdesigner</b>
            </p>

            <div className="home__img-wrapper">
                <div className="home__banner">
                    <img src={profileImg} alt="" className='home__profile' />
                </div>
                    <p className="home__data home__data-one">
                            <span className="text-lg">
                                2 <b>+</b>
                            </span>
                            <span className="text-sm text-cs">
                                Années d'<span>Experience</span>
                            </span>
                    </p>
                
                
         
                <p className="home__data home__data-two">
                    <span className="text-lg">
                        5
                    </span>
                    <span className="text-sm text-cs">
                        Projets <span>Terminés</span>
                    </span>
                </p>

                <img src={shapeOne} alt="" className="shape shape__1" />
                <img src={shapeTwo} alt="" className="shape shape__2" />
                <img src={shapeTwo} alt="" className="shape shape__3" />
            </div>

            <p className="home__text">
                Je réside dans les Hautes Alpes aux abords du lac de Serre Ponçon. Webdesigner depuis plusieurs années, je vous propose mes services.
            </p>

            <div className="home__socials">
                <a href="" className="home__social-link">
                    <FaTwitter/>
                </a>
                <a href="" className="home__social-link">
                    <FaDribbble/>
                </a>
                <a href="" className="home__social-link">
                    <FaBehance/>
                </a>
            </div>

            <div className="home__btns">
                <a href="#contact" className="btn text-cs">Me Contacter</a>
                <a href="#skills" className="hero__link text-cs">Mes compétences</a>
            </div>
        </div>

        </div>
    </section>
  )
}

export default home




