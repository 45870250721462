import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import BlogPage from "./pages/BlogPage";
import Article1 from "./articlePage/Article1";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "blog",
    element: <BlogPage />,
  },
  {
    path: "blog/article1",
    element: <Article1 />,
  },
]);

// ReactDOM.render(<App />, document.querySelector("#root"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
