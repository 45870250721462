import Project1 from './assets/project1.jpg';
import Project2 from './assets/project2.jpg';
import Project3 from './assets/project3.jpg';
import Project4 from './assets/project4.jpg';
import Project5 from './assets/project5.jpg';
import Project6 from './assets/project6.jpg';

import Testimonial1 from './assets/testimonial1.jpg';
import Testimonial2 from './assets/testimonial2.jpg';
import Testimonial3 from './assets/testimonial3.jpg';
import Testimonial4 from './assets/testimonial4.jpg';
import Testimonial5 from './assets/testimonial5.jpg';

export const links = [
  {
    name: 'Accueil',
    path: 'home',
  },
  {
    name: 'Prestations',
    path: 'services',
  },
  {
    name: 'Compétences',
    path: 'skills',
  },
  {
    name: 'Portfolio',
    path: 'work',
  },
  {
    name: 'A Propos',
    path: 'resume',
  },
  {
    name: 'Témoignages',
    path: 'testimonial',
  },
  {
    name: 'Tarifs',
    path: 'pricing',
  },
  {
    name: 'Blog',
    path: 'blog',
  },
  {
    name: 'Contact',
    path: 'contact',
  },
];

export const services = [
  {
    id: 1,
    name: 'Developpement Web',
    title: 'Création de site web',
    description:
      "Qu'il s'agisse d'un site vitrine ou d'e-commerce, laissez moi vous aider à vous donner une visibilité digne de votre marque.",
  },
  {
    id: 2,
    name: ' Création Graphique ',
    title: ' Identité Graphique ',
    description:
      "De l'esquisse au logo final, de l'idée à la naissance de votre projet, je vous accompagne dans la création de tout vos visuels.",
  },
  {
    id: 3,
    name: ' Print ',
    title: ' Infographie ',
    description:
      "Besoin de réaliser un flyer, un menu de restaurant, une carte de visite? Faites appel à moi ! ",
  },
  {
    id: 4,
    name: ' Référencement ',
    title: ' Optimisation SEO ',
    description:
      " Besoin d'une meilleure visibilité sur les moteurs de recherche? Je peux également vous aider à améliorer votre référencement a l'aide d outils. ",
  },
  {
    id: 5,
    name: ' Music Writing ',
    title: ' Sound Track ',
    description:
      "Music copying, writing, creating, transcription and composition services.",
  },
];

export const skills = [
  {
    id: 1,
    name: 'HTML / CSS',
    percentage: 90,
    description:
      "Qu'il s'agisse du balisage ou du style de votre site internet, le HTML et le CSS sont deux langages que j'utilise et maitrise depuis plusieurs années.",
  },

  {
    id: 2,
    name: 'JavaScript',
    percentage: 75,
    description:
      "Je maitrise également le JavaScript qui est un langage permettant de dynamiser et d'ajouter de nombreuses fonctions à votre site web",
  },

  {
    id: 3,
    name: 'React',
    percentage: 80,
    description:
      "La bibliothèque React permet de créer des sites et applications web monopage personnalisable a votre souhait.",
  },

  {
    id: 4,
    name: 'Worpress',
    percentage: 90,
    description:
      "J'ai également la possibilité de vous créer un site web via un CMS tel que Wordpress, le processus étant moins couteux.",
  },

  {
    id: 5,
    name: 'Suite Adobe',
    percentage: 90,
    description:
      "Qu'il s'agisse d'Illustrator, Indesign, Photoshop ou encore Adobe XD, j'utilise ces logiciels depuis plusieurs années et peux vous réaliser votre logo, charte graphique, print.",
  },

  {
    id: 6,
    name: 'Outils SEO',
    percentage: 70,
    description:
      "Je maitrise également les différents outils SEO permettant un meilleur référencement de votre site web sur les différents moteurs de recherche tel que Google",
  },
];

export const projects = [
  {
    id: 1,
    img: Project1,
    category: 'Developpement Web',
    title: 'Patiss & Cuisine',
    description:
      "Refonte de la charte graphique, création des menus, visuels réseaux sociaux et création d'un site Web www.patissetcuisine.fr",
  },
  {
    id: 2,
    img: Project2,
    category: 'Charte Graphique',
    title: "Little Noo's",
    description:
      "Création d'un logo pour un concept store de vétements et d'accessoires pour les tout petits à Embrun",
  },
  {
    id: 3,
    img: Project3,
    category: 'Developpement Web',
    title: 'Gaudineto',
    description:
      "Création de l'identité graphique, des menus et du site web du restaurant Gaudineto à Moustiers-Sainte Marie",
  },
  {
    id: 4,
    img: Project4,
    category: 'Infographie',
    title: "L'alcove",
    description:
      "Refonte du Menu du restaurant l'Alcove à Embrun",
  },
  {
    id: 5,
    img: Project5,
    category: 'Infographie',
    title: 'CCAS',
    description:
      "Création de nouvelles affiches pour le restaurant CCAS de Savines Le Lac",
  },
  {
    id: 6,
    img: Project6,
    category: 'Charte Graphique',
    title: 'Zmoon Tech',
    description:
      "Refonte d'un logo pour une société de conception informatique.",
  },
];

export const cv = [
  {
    id: 1,
    title: 'Formation Développement Web FromScratch',
    subtitle: 'Programmation FrontEnd et BackEnd',
    date: '2022 - 2023',
    description:
      "Apréhension et utilisation des différents langages de codage ainsi que de la bibliothèque React",
    category: 'education',
  },

  {
    id: 2,
    title: "Formation SEO et Affiliation Asynchrone",
    subtitle: "Utilisation des outils SEO",
    date: '2021 - 2022',
    description:
      "Découverte du référencement, des différents outils SEO visant à optimiser le référencement d'un site web.",
    category: 'education',
  },

  {
    id: 3,
    title: "Formation WebDesigner",
    subtitle: 'School Online University',
    date: '2020 - 2021',
    description:
      "Les fondamentaux du webdesign, utilisation de la suite Adobe et du CMS Wordpress",
    category: 'education',
  },

  {
    id: 4,
    title: "Face Nord Graphisme",
    subtitle: 'Autoentrepreneur',
    date: '2022 - Maintenant',
    description:
      "J'ai décidé de créer mon entreprise dans le but d'en faire mon activité principale.",
    category: 'experience',
  },

  {
    id: 5,
    title: 'Travailleur Saisonnier',
    subtitle: 'Cuisinier',
    date: '2012 - 2022',
    description:
      "Qu'il s'agisse de bons petit plats ou de site web, j'ai toujours aimé confectionner.",
    category: 'experience',
  },

  {
    id: 6,
    title: 'Apprenti Manager',
    subtitle: "BTS Management de l'unité commerciale.",
    date: '2010 - 2012',
    description:
      "J'ai été apprenti manager dans le rayon Informatique et Téléphonie d'une enseigne de grande distribution.",
    category: 'experience',
  },
];

export const testimonials = [
  {
    id: 1,
    img: Testimonial1,
    name: 'Barbara Wilson',
    author: 'CEO Company',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 2,
    img: Testimonial2,
    name: 'Charlie Smith',
    author: 'Designer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 3,
    img: Testimonial3,
    name: 'Roy Wang',
    author: 'Manager GYM',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 4,
    img: Testimonial4,
    name: 'Jennifer Smith',
    author: 'CEO & Founder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 5,
    img: Testimonial5,
    name: 'Paul Freeman',
    author: 'Photographer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];
