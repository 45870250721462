import React from 'react'
import {FaTwitter, FaDribbble, FaBehance} from 'react-icons/fa'
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container grid">
            <div className="foote__socials">
                <a href="" className="foote__social-link">
                    <FaTwitter/>
                </a>
                <a href="" className="foote__social-link">
                    <FaDribbble/>
                </a>
                <a href="" className="foote__social-link">
                    <FaBehance/>
                </a>
            </div>

            <p className="footer__copyright text-cs">&copy; 2023 <span>Francois-Xavier Pin</span>. Tout droits réservés.</p>     

            <p className="footer__copyright text-cs">Développé par <span>FaceNordGraphisme</span></p>      
        </div>    
    </footer>
  )
}

export default Footer