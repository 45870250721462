import React from 'react'
import {FaArrowRight} from 'react-icons/fa'
import Blog1 from "../../assets/blog1.jpg"
import Blog2 from "../../assets/blog2.jpg"
import Blog3 from "../../assets/blog3.jpg"
import './blog.css'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <section className="blog section" id="blog">
        <h2 className="section__title text-cs">Derniers Articles</h2>
    <p className="section__subtitle">
        Mon <span>Blog</span>
    </p>

    <div className="blog__container container grid">
        <div className="blog__item card card-two">
            <span className="blog__date text-cs">24 FEVRIER 2023</span>
            <h3 className="blog__title">Le référencement, comment ça marche ?</h3>
            <p className="blog__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde et blanditiis officiis veritatis ratione, nostrum maiores animi totam libero temporibus...</p>

            <a href="/blog" className="link">
                    Lire Plus
                    <FaArrowRight className='link__icon'></FaArrowRight>
                </a>

                <img src={Blog1} alt="" className="blog__img" />
        </div>

        <div className="blog__item card card-two">
            <span className="blog__date text-cs">4 FEVRIER 2023</span>
            <h3 className="blog__title">Les étapes importantes de la conception d'un site</h3>
            <p className="blog__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde et blanditiis officiis veritatis ratione, nostrum maiores animi totam libero temporibus...</p>

            <Link to="/blog" className="link">
                    Lire Plus
                    <FaArrowRight className='link__icon'></FaArrowRight>
                </Link>

                <img src={Blog2} alt="" className="blog__img" />
        </div>

        <div className="blog__item card card-two">
            <span className="blog__date text-cs">25 JANVIER 2023</span>
            <h3 className="blog__title">Les tendances design 2023</h3>
            <p className="blog__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde et blanditiis officiis veritatis ratione, nostrum maiores animi totam libero temporibus...</p>

            <a href="/blog" className="link">
                    Lire Plus
                    <FaArrowRight className='link__icon'></FaArrowRight>
                </a>

                <img src={Blog3} alt="" className="blog__img" />
        </div>

    </div>

    </section>
  )
}

export default Blog