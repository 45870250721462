import React from 'react'
import {FaArrowRight, FaHome} from 'react-icons/fa'
import Blog1 from "../assets/blog1.jpg"
import Blog2 from "../assets/blog2.jpg"
import Blog3 from "../assets/blog3.jpg"
import '../pages/blogPage.css'
import { Link } from 'react-router-dom'

const BlogPage = () => {
  return (
    <div>
        <a href="/" className="link">
                    HOME
                    <FaHome className='link__icon'></FaHome>
                </a>
        <section className="blog section" id="blog">
            <br /><br /><br /><br /><br /><br />
        <h2 className="section__title text-cs">Derniers Articles</h2>
    <p className="section__subtitle">
        Mon <span>Blog</span>
    </p>

    <div className="blogPage__container container grid">
        <div className="blogPage__item card card-two">
            <span className="blogPage__date text-cs">24 FEVRIER 2023</span>
            <h3 className="blogPage__title">Le référencement, comment ça marche ?</h3>
            <p className="blogPage__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde et blanditiis officiis veritatis ratione, nostrum maiores animi totam libero temporibus...</p>

            <a href="blog/article1" className="link">
                    Lire Plus
                    <FaArrowRight className='link__icon'></FaArrowRight>
                </a>

                <img src={Blog1} alt="" className="blogPage__img" />
        </div>

        <div className="blogPage__item card card-two">
            <span className="blogPage__date text-cs">4 FEVRIER 2023</span>
            <h3 className="blogPage__title">Les étapes importantes de la conception d'un site</h3>
            <p className="blogPage__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde et blanditiis officiis veritatis ratione, nostrum maiores animi totam libero temporibus...</p>

            <a href="/article2" className="link">
                    Lire Plus
                    <FaArrowRight className='link__icon'></FaArrowRight>
                </a>

                <img src={Blog2} alt="" className="blogPage__img" />
        </div>

        <div className="blogPage__item card card-two">
            <span className="blogPage__date text-cs">25 JANVIER 2023</span>
            <h3 className="blogPage__title">Les tendances design 2023</h3>
            <p className="blogPage__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde et blanditiis officiis veritatis ratione, nostrum maiores animi totam libero temporibus...</p>

            <a href="/article3" className="link">
                    Lire Plus
                    <FaArrowRight className='link__icon'></FaArrowRight>
                </a>

                <img src={Blog3} alt="" className="blogPage__img" />
        </div>

    </div>

    </section>
        
    </div>
  )
}

export default BlogPage