import React from 'react'
import shapeTwo from '../../assets/shape-2.png'
import { FaCheck, FaArrowRight } from 'react-icons/fa'
import './pricing.css'

const Pricing = () => {
  return (
    <section className="pricing section" id="pricing">
            <h2 className="section__title text-cs">Prestations</h2>
    <p className="section__subtitle">
        Mes <span>Tarots</span>
    </p>

    <div className="pricing__container container grid">
        <div className="pricing__item card card-one">
            <span className="pricing__subtitle text-cs">Graphisme</span>
            <h3 className="pricing__price"><em>A Partir De </em> 90<span>€</span></h3>
            
            <p className="pricing__description">Que ce soit le raffraichissement ou la création d'un logo, de la réalisation d'une charte graphique, de la conception d'un Menu, d'un Flyer je peux réaliser pour vous toutes sortes d'infographies.</p>

            <ul className="pricing__list">
                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Création de Logo</span>
                </li>

                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Infographies</span>
                </li>
                <li className="list__item">
                    <del>Développement Web</del>
                </li>
                <li className="list__item">
                    <del>Référencement</del>
                </li>
            </ul>

            <a href="" className="btn pricing__btn text-cs">
                En Découvrir Plus
                <FaArrowRight className='pricing__btn-icon'></FaArrowRight>
            </a>

            <img src={shapeTwo} alt="" className="shape c__shape" />   
        </div>


        <div className="pricing__item-wrapper">
            <span className="pricing__label text-cs">Populaire</span>
        <div className="pricing__item card card-one">
            <span className="pricing__subtitle text-cs">Création de site web</span>
            <h3 className="pricing__price"><em>A Partir De </em> 500<span>€</span></h3>
            
            <p className="pricing__description">Vous venez de lancer votre marque et vous désirez désormais créer votre site web afin d'améliorer votre visibilité numérique? Vous êtes au bon endroit; que ce soit un site creé avec un CMS tel que Wordpress ou le développement complet d'un site grace a React, il y a forcément une formule qui vous convient. </p>

            <ul className="pricing__list">
                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Développement Web</span>
                </li>

                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Référencement</span>
                </li>
                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <del>Création de Logo</del>
                </li>
                <li className="list__item">
                    <del>Infographies</del>
                </li>
            </ul>

            <a href="" className="btn pricing__btn text-cs">
                En Découvrir Plus
                <FaArrowRight className='pricing__btn-icon'></FaArrowRight>
            </a>

            <img src={shapeTwo} alt="" className="shape c__shape" />   
        </div>
        </div>

        <div className="pricing__item card card-one">
            <span className="pricing__subtitle text-cs">Création d'une marque</span>
            <h3 className="pricing__price"><em>A Partir De </em> 1500<span>€</span></h3>
            
            <p className="pricing__description">Vous désirez créer votre marque mais n'avez pas la fibre créative? Laissez moi m'occuper de tout, de la création du logo, de votre charte graphique ainsi que de votre site web</p>

            <ul className="pricing__list">
                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Création de Logo</span>
                </li>

                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Charte Graphique</span>
                </li>
                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Développement Web</span>
                </li>
                <li className="list__item">
                    <FaCheck className='list__icon'></FaCheck>
                    <span>Référencement</span>
                </li>
            </ul>

            <a href="" className="btn pricing__btn text-cs">
                En Découvrir Plus
                <FaArrowRight className='pricing__btn-icon'></FaArrowRight>
            </a>

            <img src={shapeTwo} alt="" className="shape c__shape" />   
        </div>

    </div>
    </section>
  )
}

export default Pricing