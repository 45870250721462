// https://docs.google.com/spreadsheets/d/1dGzEnicyftxP5Um_jPmDMsZWiM1GEpEurwEbb77FuXQ/edit#gid=0 yyyn
import axios from 'axios'
import React, {useState} from 'react'
import {FaRegAddressBook, FaRegAddressCard, FaRegUser, FaRegMap, FaRegEnvelope} from 'react-icons/fa'
import './contact.css'

const Contact = () => {
    const [form, setForm] = useState({
        name: '',
         email: '',
          subject: '',
           message: '',
        })

        const handleChange = (e) => {
            const name = e.target.name
            const value = e.target.value
            setForm({...form, [name]: value })
        }

        const handleSubmit = (e) => {
            e.preventDefault()

            axios.post('https://sheet.best/api/sheets/6f0c4013-2b7d-466d-9b9d-e9d993965ef0', 
            form
            ).then((response) => {
                console.log(response)
                //clearing form fields
                setForm({name: '', email:'', subject: '', message: ''})
            })
        }
  return (
    <section className="contact section" id="contact">
        <h2 className="section__title text-cs">Contactez Moi</h2>
    <p className="section__subtitle">
        Parlons De<span>Votre Projet</span>
    </p>

    <div className="contact__container container grid">
        <div className="contact__content">
            <div className="contact__card">
                <span className="contact__card-icon">
                    <FaRegMap/>
                </span>

                <h3 className="contact__card-title">Adresse</h3>
                <p className="contact__card-data">Route de La Cabane, 05200 Saint André d'Embrun</p>
            </div>

            <div className="contact__card">
                <span className="contact__card-icon">
                    <FaRegUser/>
                </span>

                <h3 className="contact__card-title">Freelance</h3>
                <p className="contact__card-data">Disponible tout de suite</p>
            </div>

            <div className="contact__card">
                <span className="contact__card-icon">
                    <FaRegEnvelope/>
                </span>

                <h3 className="contact__card-title">Email</h3>
                <p className="contact__card-data">facenordgraphisme@gmail.com</p>
            </div>

            <div className="contact__card">
                <span className="contact__card-icon">
                    <FaRegAddressBook/>
                </span>

                <h3 className="contact__card-title">Telephone</h3>
                <p className="contact__card-data">+33 06 23 51 86 14</p>
            </div>
        </div>

        <form className="contact__form" onSubmit={handleSubmit}>
            <div className="contact__form-group grid">
                <div className="contact__form-div">
                    <label htmlFor="" className="contact__form-tag text-cs">Votre Nom Complet <b>*</b></label>
                    <input type="text" name='name' onChange={handleChange} value={form.name} className="contact__form-input" />
                </div>

                <div className="contact__form-div">
                    <label htmlFor="" className="contact__form-tag text-cs">Votre Adresse Mail <b>*</b></label>
                    <input type="email" name='email' onChange={handleChange} value={form.email}  className="contact__form-input" />
                </div>
            </div>
            

            <div className="contact__form-div">
                    <label htmlFor="" className="contact__form-tag text-cs">Votre Projet <b>*</b></label>
                    <input type="text" name='subject' onChange={handleChange} value={form.subject}  className="contact__form-input" />
                </div>

                <div className="contact__form-div contact__form-area">
                    <label htmlFor="" className="contact__form-tag text-cs">Votre Message <b>*</b></label>
                    <textarea name='message' onChange={handleChange} value={form.message}  className='contact__form-input'></textarea>
                </div>

                <div className="contact__submit">
                    <p>* Accepter les termes et conditions.</p>
                    <button type='submit' className='btn text-cs'>Envoyer</button>
                </div>
        </form>
    </div>

    </section>
  )
}

export default Contact